<template>
  <div v-html="remark" class="content"></div>
</template>
<script>
import { getQuota } from "@/api/user";
export default {
  data() {
    return {
      remark:null
    };
  },
  // s=6375114b-06b1-4f12-98a6-e31c4dba739a&id=27025241&sn=e9b954c426b3df9451c7545d68cf131d
  created() {
    const params = {
      app_id: null,
      project_id: null,
    };
    params.app_id = this.$route.query.sub_id || null;
    params.project_id = this.$route.query.project_id || null;
    getQuota(params).then((res) => {
      if (res.status === 200) {
        this.remark=res.data.remark
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.content{
  width: 100%;
  text-align: center;
}
</style>